import React from 'react';
import { Link } from 'gatsby';
import Company from  '../../data/company-data'

const Footer = () => (
  <footer className="py-10 px-3 text-gray-800 bg-gray-200 shadow md:fixed bottom-0 w-full">
    <div className="container mx-auto">
      <div className="xl:flex xl:-mx-3 xl:justify-center xl:items-end">
        <div className="flex-1 px-3 mb-10 text-center xl:text-left xl:mb-0">
          <Link to="/">Startseite</Link><span className="text-gray-500"> | </span><Link to="/datenschutz">Datenschutz</Link><span className="text-gray-500"> | </span><Link to="/impressum">Impressum</Link>
        </div>
        <div className="flex-1 px-3 text-center xl:text-right">
          Copyright © {new Date().getFullYear()} {Company.name} - {Company.owner}. Alle Rechte vorbehalten.
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
