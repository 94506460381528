export default {
  name: 'Daten-Strom-GP',
  owner: 'Gerald Prager',
  url: 'https://www.daten-strom.info/',
  urlShort: 'daten-strom.info',
  phone: '+4936602509865',
  phoneLabel: '036602 - 509 865',
  phoneInternational: '+49-36602-509865',
  faxInternational: '+49-36602-509643',
  street: 'Hauptstraße 39',
  city: 'Großenstein',
  postCode: '07580 ',
  ustId: 'DE245763605'
};
